import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";

export function useFontSizes() {
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  return {
    HeadingBig: isSmallScreen ? "1.3rem" : "3rem",
    HeadingRegular: isSmallScreen ? "1.2rem" : "1.5rem",
    HeadingSmaller: isSmallScreen ? "1rem" : "1.25rem",
    ParagraphBig: isSmallScreen ? "0.95rem" : "1.15rem",
    ParagraphRegular: isSmallScreen ? "0.9rem" : "1rem",
    ParagraphSmaller: isSmallScreen ? "0.75rem" : "0.95rem",
    ParagraphSmallest: isSmallScreen ? "0.75rem" : "0.85rem",
    IconsRegular: isSmallScreen ? "1rem" : "1.2rem",
  };
}
