import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "../App.css";
import "./mainpage.css";
import ModeToggle from "../components/header/components/SettingsMenu";
import { isMobile } from "react-device-detect";
import HeaderMobile from "../components/header-mobile/HeaderMobile";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../store/reducers/layoutSlice";
import VideoText from "../components/mainpage/VideoText";
import { useTheme } from "@emotion/react";
import SmokeBg from "../components/mainpage/SmokeBg";
import WhatWeOffer from "../components/mainpage/what-we-offer/WhatWeOffer";
import AiComingSoon from "../components/mainpage/ai/AiComingSoon";

function Mainpage({ isPlaying, togglePlay }) {
  const theme = useTheme();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  return (
    <div
      className="App"
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      <div className="video-container">
        <SmokeBg isPlaying={isPlaying} />
        <VideoText isPlaying={isPlaying} togglePlay={togglePlay} />
      </div>
      {/* <div className="divider-container">
        <Divider sx={{ width: "100%" }} />
      </div> */}
       {/* <AboutUsShort /> */}
      <WhatWeOffer />
      <AiComingSoon />
      
      <Footer />
    </div>
  );
}

export default Mainpage;
