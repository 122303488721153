import "./videotext.css";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { selectActiveLanguage } from "../../store/reducers/userSlice";
import { useFontSizes } from "../../helpers/hooks/useFontSizes";

const translations = {
  en: "Your Vision. Our Solution.",
  tr: "Vizyonunuz. Bizim Çözümümüz.",
  sr: "Vaša Vizija. Naše Rešenje.",
};

function VideoText() {
  const theme = useTheme();
  const activeLanguage = useSelector(selectActiveLanguage);
  const { HeadingBig } = useFontSizes();

  const text = translations[activeLanguage];

  return (
    <div className="video-text-container">
      {text.split("").map((char, index) => (
        <span
          style={{
            color: theme.palette.text.secondary,
            fontSize: HeadingBig, 
            fontWeight: 700, // Bold font
            letterSpacing: "0.1em", 
            textShadow: "0.022em 0.022em 0.022em #111", 
            animationDelay: `${0.08 + index * 0.08}s`,
          }}
          key={index}
        >
          {char === " " ? "\u00A0" : char}
        </span>
      ))}
    </div>
  );
}

export default VideoText;

