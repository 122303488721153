import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage, LANGUAGE_OPTIONS } from "../../../store/reducers/userSlice";
import { useTranslation } from "react-i18next";
import { ReactComponent as EnglishIcon } from "../../../media/language/english.svg";
import { ReactComponent as TurkishIcon } from "../../../media/language/turkey.svg";
import { ReactComponent as SerbianIcon } from "../../../media/language/serbian.svg";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";

const languageIcons = {
  [LANGUAGE_OPTIONS.ENGLISH]: EnglishIcon,
  [LANGUAGE_OPTIONS.TURKISH]: TurkishIcon,
  [LANGUAGE_OPTIONS.SERBIAN]: SerbianIcon,
};

function LanguageDropdown({ theme }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ParagraphSmallest } = useFontSizes();
  const currentLanguage = useSelector((state) => state.user.language);

  const [isHovered, setIsHovered] = useState(false);
  const [isRotating, setIsRotating] = useState(false);

  const handleLanguageChange = (language) => {
    if (language !== currentLanguage) {
      setIsRotating(true); // Start rotation
      setTimeout(() => {
        dispatch(setLanguage({ language })); // Change language after rotation
        setIsRotating(false); // Reset rotation state
      }, 300); // Match rotation animation duration
    }
  };

  const CurrentLanguageIcon = languageIcons[currentLanguage] || EnglishIcon;

  return (
    <div
      className="language-dropdown-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        fontSize: ParagraphSmallest,
        cursor: "pointer",
        position: "relative",
      }}
    >
      <div
        className="language-selected"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          minHeight: "35px",
        }}
      >
        <CurrentLanguageIcon
          style={{
            width: "20px",
            height: "20px",
            transition: "transform 0.3s ease", // Smooth rotation
            transform: isRotating ? "rotate(360deg)" : "rotate(0deg)",
          }}
        />
        <div className={isHovered && "dropdown-text-hovered-const"}>{t("language")}</div>
      </div>

      <div
        className={`language-dropdown ${isHovered ? "appear" : "disappear"}`}
        style={{
          overflow: "hidden",
          visibility: isHovered ? "visible" : "hidden",
          opacity: isHovered ? 1 : 0,
          transform: isHovered ? "scale(1)" : "scale(0.95)",
          transition: "opacity 0.2s ease, transform 0.2s ease",
        }}
      >
        {Object.keys(languageIcons).map((lang) => (
          <div
            key={lang}
            onClick={() => handleLanguageChange(lang)}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "7px 15px",
              cursor: "pointer",
              color: theme.palette.text.primary,
              transition: "background-color 0.3s ease",
            }}
          >
            {React.createElement(languageIcons[lang], {
              style: { width: "20px", height: "20px", marginRight: "10px" },
            })}
            {lang}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LanguageDropdown;
