import "./what-we-offer.css";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { useTheme } from "@emotion/react";
import { Divider } from "@mui/material";
import { useServicesData } from "../../../helpers/hooks/useServices";
import { selectScreenSize } from "../../../store/reducers/layoutSlice";
import { useIntersectionObserver } from "../../../helpers/hooks/useIntersectionObserver";
import { useTranslation } from "react-i18next";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";
import WhatWeOfferCard from "./WhatWeOfferCard";

function WhatWeOffer() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { HeadingRegular } = useFontSizes();

  const services = useServicesData();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  const [middleIndex, setMiddleIndex] = useState(0);

  const { ref: sectionRef, inView: sectionInView } = useIntersectionObserver({
    threshold: 0.1,
    rootMargin: "100px 0px",
  });
  const { ref: titleRef, inView: titleInView } = useIntersectionObserver();
  const { ref: dividerRef, inView: dividerInView } = useIntersectionObserver();
  const { ref: cardsRef, inView: cardsInView } = useIntersectionObserver();

  const shiftLeft = () => {
    setMiddleIndex((prev) => (prev === 0 ? services.length - 1 : prev - 1));
  };

  const shiftRight = () => {
    setMiddleIndex((prev) => (prev === services.length - 1 ? 0 : prev + 1));
  };

  const handleCardClick = (clickedIndex) => {
    const middleIndex = Math.floor(services.length / 2);
    const shiftCount = clickedIndex - middleIndex;

    if (shiftCount > 0) {
      Array.from({ length: shiftCount }).forEach(() => shiftRight());
    } else {
      Array.from({ length: Math.abs(shiftCount) }).forEach(() => shiftLeft());
    }
  };

  useEffect(() => {
    const interval = setInterval(shiftRight, 4000);
    return () => clearInterval(interval);
  }, []);

  const getCardClass = (index) => {
    const offset = (index - middleIndex + services.length) % services.length;

    switch (offset) {
      case 0:
        return "middle";
      case 1:
        return "adjacent-right";
      case 2:
        return "smallest-right";
      case services.length - 1:
        return "adjacent-left";
      case services.length - 2:
        return "smallest-left";
      default:
        return "hidden";
    }
  };

  return (
    <section
      ref={sectionRef}
      className="what-we-offer"
      style={{ color: theme.palette.text.primary }}
    >
      {/* Heading Section */}
      <div
        className="what-we-offer-heading"
        style={{
          fontSize: HeadingRegular,
          alignItems: isSmallScreen && "center",
        }}
      >
        <h2
          ref={titleRef}
          className={`what-we-offer-title ${titleInView ? "visible" : ""}`}
        >
          {t("whatWeOffer")}
        </h2>
      </div>

      {/* Divider */}
      <div
        ref={dividerRef}
        className={`divider-container-what-we-offer ${
          dividerInView ? "visible" : ""
        }`}
      >
        <Divider sx={{ width: "95%" }} />
      </div>

      {/* Cards Section */}
      <div ref={cardsRef} className={`cards-container ${cardsInView ? "visible" : ""}`}>
        {services.map((service, index) => (
          <WhatWeOfferCard
            key={service.id}
            icon={service.icon}
            title={service.title}
            image={service.image}
            description={service.description}
            className={`card-single ${getCardClass(index)}`}
            isSmallScreen={isSmallScreen}
          />
        ))}
      </div>
    </section>
  );
}

export default WhatWeOffer;
