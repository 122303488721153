import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreation } from "./theme/designTokens";
import { useSelector } from "react-redux";
import { selectActiveTheme } from "./store/reducers/userSlice";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ScreenSizeListener from "./helpers/other/ScreenSizeListener";
import { Route, Routes } from "react-router-dom";
import { selectScreenSize } from "./store/reducers/layoutSlice";
import LoadingModal from "./components/other/LoadingModal";
import Mainpage from "./pages/Mainpage";
import HeaderMobile from "./components/header-mobile/HeaderMobile";
import Header from "./components/header/Header";
import { Divider } from "@mui/material";

function App() {
  const themeMode = useSelector(selectActiveTheme);
  const [theme, setTheme] = useState(themeCreation());
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const [isPlaying, setIsPlaying] = useState(true);
  const togglePlay = () => setIsPlaying((prevState) => !prevState);

  useEffect(() => {
    setTheme(themeCreation(themeMode));
    document.documentElement.style.setProperty(
      "--scrollbar-thumb-color",
      themeCreation(themeMode).palette.text.third
    );
  }, [themeMode]);
  return (
    <ThemeProvider theme={theme}>
      <ScreenSizeListener />

      {isSmallScreen ? (
        <HeaderMobile isPlaying={isPlaying} togglePlay={togglePlay} />
      ) : (
          <Header isPlaying={isPlaying} togglePlay={togglePlay} />
      )}

      <Routes>
        <Route path="/" element={<Mainpage isPlaying={isPlaying} togglePlay={togglePlay}/>} />
        {/* Add more Route components here as needed */}
      </Routes>

      <LoadingModal />
    </ThemeProvider>
  );
}

export default App;
