import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import webGLFluidEnhanced from "webgl-fluid-enhanced";
import { selectActiveTheme } from "../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import fluidVideo from "../../media/video-mobile/fluid.mp4";
import fluidDarkVideo from "../../media/video-mobile/fluid-dark.mp4";
import "./smokebg.css";
import { useTheme } from "@emotion/react";

const SmokeBg = ({ isPlaying }) => {
  const theme = useTheme();
  const canvasRef = useRef(null);
  const containerRef = useRef(null); // Ref for the container to observe
  const fluidSimulation = useRef(null);
  const themeMode = useSelector(selectActiveTheme);
  const [videoSrc, setVideoSrc] = useState(fluidVideo);
  const [isVisible, setIsVisible] = useState(false); // Track visibility

  // Update video source when themeMode changes
  useEffect(() => {
    setVideoSrc(themeMode === "dark" ? fluidDarkVideo : fluidVideo);
  }, [themeMode]);

  // Use Intersection Observer to track visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when at least 10% of the element is visible
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Initialize WebGL Fluid simulation
  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas && isPlaying && isVisible) {
      fluidSimulation.current = webGLFluidEnhanced.simulation(canvas, {
        BRIGHTNESS: 0.5,
        SIM_RESOLUTION: 64,
        DYE_RESOLUTION: 512,
        CAPTURE_RESOLUTION: 256,
        DENSITY_DISSIPATION: 0.4,
        VELOCITY_DISSIPATION: 0.02,
        PRESSURE: 0.2,
        PRESSURE_ITERATIONS: 10,
        CURL: 30,
        INITIAL: true,
        SPLAT_AMOUNT: 2,
        SPLAT_RADIUS: 0.18,
        SPLAT_FORCE: 4000,
        SPLAT_KEY: "Space",
        SHADING: true,
        COLORFUL: true,
        COLOR_PALETTE: [
          "#6ae7da",
          "#55d1c6",
          "#42bdb2",
          "#2c5e54",
          "#1b4332",
          "#0b2d20",
          "#0f3b3b",
          "#0d2626",
          "#15252d",
          "#0c1a1a",
          themeMode === "dark" ? "#2A6358" : "#FFFFFF",
        ],
        HOVER: true,
        BACK_COLOR: themeMode === "dark" ? "#2A6358" : "#FFFFFF",
        TRANSPARENT: false,
        BLOOM: true,
        BLOOM_ITERATIONS: 6,
        BLOOM_RESOLUTION: 256,
        BLOOM_INTENSITY: themeMode === "dark" ? 0.1 : 0.6,
        BLOOM_THRESHOLD: 0.6,
        BLOOM_SOFT_KNEE: 0.7,
        SUNRAYS: true,
        SUNRAYS_RESOLUTION: 196,
        SUNRAYS_WEIGHT: themeMode === "dark" ? 0.4 : 1.0,
      });
    }

    return () => {
      if (fluidSimulation.current) {
        fluidSimulation.current = null; // Stop simulation
      }
    };
  }, [themeMode, isPlaying, isVisible]); // Re-run effect when themeMode, isPlaying, or visibility changes

  return (
    <div
      className="smoke-container"
      ref={containerRef} // Attach the container ref for Intersection Observer
      style={{
        height: "100vh",
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      {isPlaying && isVisible && (
        <canvas
          ref={canvasRef}
          style={{ width: "100%", minHeight: "100vh" }}
        ></canvas>
      )}
    </div>
  );
};

export default SmokeBg;
