import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Logo from "./components/Logo";
import LanguageSelect from "./LanguageSelect";
import { useTranslation } from "react-i18next";
import { useFontSizes } from "../../helpers/hooks/useFontSizes";
import SettingsIcon from "@mui/icons-material/Settings";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";
import SettingsMenu from "./components/SettingsMenu";
import {
  selectActiveTheme,
  setThemeMode,
} from "../../store/reducers/userSlice";
import { Divider } from "@mui/material";

function Header({ isPlaying, togglePlay }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ParagraphBig, ParagraphRegular } = useFontSizes();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const currentTheme = useSelector((state) => state.user.theme);
  const [scrollPosition, setScrollPosition] = useState(0);
  const themeMode = useSelector(selectActiveTheme);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsSettingsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSettingsMenuOpen(false);
  };

  const handleModeSelect = (mode) => {
    if (currentTheme !== mode) {
      dispatch(setThemeMode(mode));
    }
  };

  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setScrollPosition(window.scrollY);
      }, 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const gradient =
    scrollPosition > 0
      ? `linear-gradient(90deg,
       ${theme.palette.primary.mainGradientDarker} 0%, 
       ${theme.palette.lightblue.lighter} 50%, 
       ${theme.palette.primary.squaresHeader} 100%)`
      : "transparent";

  const dividerWidth = `${Math.min(97 + (scrollPosition / 400) * 3, 100)}%`;

  return (
    <div className="header" onMouseLeave={handleMouseLeave}>
      <div
        className="header-gradient"
        style={{
          background: gradient,
          opacity: scrollPosition > 0 ? 1 : 0,
        }}
      >
        <div
          className="header-squares"
          style={{
            "--square-color": theme.palette.primary.mainGradientLighter,
            opacity: scrollPosition > 0 ? 1 : 0,
          }}
        ></div>
      </div>
      <div
        className="header-container"
        style={{
          color: theme.palette.text.primary,
          "--border-color": `${theme.palette.text.primaryBorder}`,
          fontSize: screenSize === "medium-s" ? ParagraphRegular : ParagraphBig,
        }}
      >
        <Logo />

        <div
          className="menu-desktop"
          style={{
            "--text-color":
              themeMode === "light"
                ? "rgba(0, 0, 0, 1)"
                : "rgba(255, 255, 255, 1)",
            "--text-color2":
              themeMode === "light"
                ? "rgba(53, 118, 112, 1)"
                : "rgba(249, 233, 153, 1)",
            "--text-color3":
              themeMode === "light"
                ? "rgba(73, 202, 190, 1)"
                : "rgba(97, 226, 210, 1)",
          }}
        >
          <div className="text-hovered" onClick={() => navigate("/about-us")}>
            {t("aboutUs")}
          </div>
          <div className="text-hovered" onClick={() => navigate("/services")}>
            {t("services")}
          </div>

          <SettingsIcon
            sx={{
              fontSize: "2.2em",
              cursor: "pointer",
              color:
                themeMode === "light"
                  ? "rgba(0, 0, 0, 1)"
                  : "rgba(255, 255, 255, 1)",
              transition: "transform 0.3s ease-in-out, color 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
              "&:hover": {
                animation: "colorCycle 7s infinite",
              },
              "@keyframes colorCycle": {
                "0%": {
                  color:
                    themeMode === "light"
                      ? "rgba(0, 0, 0, 1)"
                      : "rgba(255, 255, 255, 1)",
                },
                "33%": {
                  color:
                    themeMode === "light"
                      ? "rgba(53, 118, 112, 1)"
                      : "rgba(249, 233, 153, 1)",
                },
                "66%": {
                  color:
                    themeMode === "light"
                      ? "rgba(73, 202, 190, 1)"
                      : "rgba(97, 226, 210, 1)",
                },
                "100%": {
                  color:
                    themeMode === "light"
                      ? "rgba(0, 0, 0, 1)"
                      : "rgba(255, 255, 255, 1)",
                },
              },
            }}
            onMouseEnter={handleMouseEnter}
            onClick={() => setIsSettingsMenuOpen((prev) => !prev)}
          />
        </div>
      </div>

      <div className="divider-container">
        <Divider
          sx={{
            width: dividerWidth,
            height: "1.5px",
            transition: "width 0.5s ease",
            color: theme.palette.text.primary,
            zIndex: 100,
          }}
        />
      </div>
      <SettingsMenu
        currentTheme={currentTheme}
        handleModeSelect={handleModeSelect}
        isPlaying={isPlaying}
        togglePlay={togglePlay}
        theme={theme}
        menuState={isSettingsMenuOpen ? "appear" : "disappear"}
      />
    </div>
  );
}

export default Header;
