import React from "react";
import "./settings.css";
import PlayPauseButton from "./PlayPauseButton";
import ThemeToggle from "./ThemeToggle";
import LanguageDropdown from "./LanguageDropdown";
import { Divider } from "@mui/material";

function SettingsMenu({
  currentTheme,
  handleModeSelect,
  isPlaying,
  togglePlay,
  theme,
  menuState,
}) {
  return (
    <div
      className={`settings-menu ${menuState}`}
      style={{
        color: theme.palette.text.primary,
      }}
    >
      <div className="settings-menu-container">
        <PlayPauseButton
          isPlaying={isPlaying}
          togglePlay={togglePlay}
          theme={theme}
        />
      </div>
      <div className="settings-menu-container">
        <ThemeToggle
          currentTheme={currentTheme}
          handleModeSelect={handleModeSelect}
          theme={theme}
        />
      </div>
      <div className="settings-menu-container">
        <LanguageDropdown theme={theme} />
      </div>
        <Divider
          sx={{
            width: "105%",
            height: "1.5px",
            transition: "width 0.5s ease",
            color: theme.palette.text.primary,
            zIndex: 100,
          }}
        />
    </div>
  );
}

export default SettingsMenu;
