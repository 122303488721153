import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useTranslation } from "react-i18next";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";

function PlayPauseButton({ isPlaying, togglePlay, theme }) {
  const { ParagraphSmallest } = useFontSizes();
  const { t } = useTranslation();

  return (
    <div
      className="settings-button"
      onClick={togglePlay}
      style={{
        color: theme.palette.text.primary,
        // "--border-color": `${theme.palette.text.primaryBorderButton}`,
        fontSize: ParagraphSmallest,
      }}
    >
  <div
        style={{
          width: "21px",
          height: "21px",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.mainGradientLighter,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "8px",
        }}
      >
        {isPlaying ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
      </div>
      <div className="dropdown-text-hovered">{isPlaying ? t("pause") : t("play")}</div>
    </div>
  );
}

export default PlayPauseButton;