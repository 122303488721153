import React from "react";
import "./footer.css";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";
import { ReactComponent as LogoBorderBlack } from "../../media/logo/logo-single-black-border.svg";
import { ReactComponent as LogoBorderWhite } from "../../media/logo/logo-single-white-border.svg";
import { useTheme } from "@emotion/react";
import { selectActiveTheme } from "../../store/reducers/userSlice";
import { useFontSizes } from "../../helpers/hooks/useFontSizes";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

function Footer() {
  const screenSize = useSelector(selectScreenSize);
  const theme = useTheme();
  const themeMode = useSelector(selectActiveTheme);
  const { ParagraphRegular } = useFontSizes();

  const email = "emre@artyxcreative.com";
  const phoneNumber = "+ 381 69 5250012";
  return (
    <div
      className="footer-container"
      style={{
        height: isMobile || screenSize === "small" ? "200px" : "220px",
        "--border-color": `${theme.palette.text.primaryBorder}`,
        fontSize: ParagraphRegular,
        fontWeight:"300"
      }}
    >
      <div className="footer-logo">
        {themeMode === "light" ? (
          <LogoBorderBlack className="overlay-logo" />
        ) : (
          <LogoBorderWhite className="overlay-logo" />
        )}
      </div>
      <h3
        className="footer-heading"
        style={{ color: theme.palette.text.primary, fontWeight: "400" }}
      >
        Contact | Socials
      </h3>
      <p style={{ color: theme.palette.text.primary }}>{email}</p>
      <p style={{ color: theme.palette.text.primary }}>{phoneNumber}</p>
    </div>
  );
}

export default Footer;
