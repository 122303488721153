import React, { useEffect, useRef, useState } from "react";
import "./rotating-circle.css";
import { useTheme } from "@emotion/react";
import { selectActiveTheme } from "../../../store/reducers/userSlice";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import ScreenSizeListener from "../../../helpers/other/ScreenSizeListener";
import { useIntersectionObserver } from "../../../helpers/hooks/useIntersectionObserver";

const clamp = (value, min, max) => Math.max(min, Math.min(value, max));

const throttle = (func, limit) => {
  let lastRan = 0;
  return (...args) => {
    const now = Date.now();
    if (now - lastRan > limit) {
      func(...args);
      lastRan = now;
    }
  };
};

const RotatingCircle = () => {
  const theme = useTheme();
  const circleRef = useRef(null);
  const themeMode = useSelector(selectActiveTheme);
  const isSmallScreen = ScreenSizeListener === "small" || isMobile;

  const [eyeDirection, setEyeDirection] = useState({ x: 0, y: 0 });
  const [currentDirection, setCurrentDirection] = useState({ x: 0, y: 0 });
  const { ref: intersectionRef, inView } = useIntersectionObserver(0.1);

  // Mouse move handling with throttling
  useEffect(() => {
    if (inView) {
      setCurrentDirection(eyeDirection); // Snap directly on mount
    }
    const handleMouseMove = throttle((e) => {
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const targetX = ((e.clientX - centerX) / centerX) * 30;
      const targetY = ((e.clientY - centerY) / centerY) * 30;

      setEyeDirection((prev) => {
        const newDirection = {
          x: clamp(targetX, -30, 30),
          y: clamp(targetY, -30, 30),
        };
        if (prev.x === newDirection.x && prev.y === newDirection.y) return prev;
        return newDirection;
      });
    }, 50);

    if (inView) {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [inView]);

  useEffect(() => {
    if (!inView) return;

    let initialAnimation = true; // To detect the first render

    const smoothMovement = () => {
      setCurrentDirection((prev) => {
        const dx = eyeDirection.x - prev.x;
        const dy = eyeDirection.y - prev.y;

        if (Math.abs(dx) < 0.1 && Math.abs(dy) < 0.1) return prev; // Skip if change is too small

        return {
          x: prev.x + dx * 0.2,
          y: prev.y + dy * 0.2,
        };
      });

      requestAnimationFrame(smoothMovement);
    };

    const animationId = requestAnimationFrame(smoothMovement);

    return () => cancelAnimationFrame(animationId);
  }, [eyeDirection, inView]);

  return (
    <div
      className="pattern-container"
      ref={(node) => {
        circleRef.current = node;
        intersectionRef.current = node; // Assign to the hook's ref
      }}
      style={{
        width: isSmallScreen ? "100vw" : "50vw",
        height: isSmallScreen ? "60vh" : "60vh",
        "--square-color": theme.palette.primary.lighter,
        backgroundColor:
          isSmallScreen && themeMode === "light"
            ? "rgb(49 120 124 / 56%)"
            : theme.palette.primary.squares2,
        mask: isSmallScreen
          ? themeMode === "light"
            ? "radial-gradient(circle, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 90%)"
            : "radial-gradient(circle, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 65%)"
          : themeMode === "light"
          ? "radial-gradient(circle, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 85%)"
          : "radial-gradient(circle, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 40%)",
        WebkitMask: isSmallScreen
          ? themeMode === "light"
            ? "radial-gradient(circle, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 70%)"
            : "radial-gradient(circle, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 70%)"
          : themeMode === "light"
          ? "radial-gradient(circle, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 85%)"
          : "radial-gradient(circle, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 50%)",
      }}
    >
      <div className="rotating-circle-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 400 400"
          className="svg-design"
        >
          <defs>
            <linearGradient id="redGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="rgba(0, 255, 255, 0.8)" />
              <stop offset="100%" stopColor="rgba(0, 255, 255, 0)" />
            </linearGradient>
          </defs>
          <defs>
            <radialGradient id="neonGradient" cx="50%" cy="50%" r="50%">
              <stop offset="0%" stopColor="rgba(255, 255, 255, 0.8)" />{" "}
              {/* Bright center */}
              <stop offset="30%" stopColor="rgba(0, 212, 255, 1)" />
              <stop offset="100%" stopColor="rgba(0, 212, 255, 0)" />
            </radialGradient>
          </defs>

          {/* Central Glow Effect */}
          <circle
            cx="200"
            cy="200"
            r="50" /* Smaller radius for central glow */
            fill="rgba(255, 255, 255, 0.3)" /* Light glow */
            style={{
              filter: "blur(10px)" /* Creates the glowing effect */,
              opacity: 0.6,
              pointerEvents:
                "none" /* Ensures no interference with interactions */,
            }}
          />
          {/* Glowing Circle */}
          <circle
            cx="200"
            cy="200"
            r="100"
            fill="url(#neonGradient)" /* Gradient for the glow */
            className="glowing-circle"
            style={{
              transform: `translate(${currentDirection.x}px, ${currentDirection.y}px) translateZ(0)`,
              transition: "transform 0.2s ease-out",
            }}
          />

          {/* Gradient Definition */}
          <defs>
            <radialGradient id="neonGradient" cx="50%" cy="50%" r="50%">
              <stop offset="30%" stopColor="rgba(0, 212, 255, 1)" />
              <stop offset="100%" stopColor="rgba(0, 212, 255, 0)" />
            </radialGradient>
          </defs>

          {/* Hollow Inner Circle */}
          <circle
            cx="200"
            cy="200"
            r="100"
            fill="none"
            stroke="#00d4ff"
            strokeWidth="10"
            className="inner-circle"
          />

          {/* Outer Circle with Segmented Lines */}
          <g className="outer-segments">
            {[...Array(100)].map((_, i) => (
              <line
                key={i}
                x1="200"
                y1="20"
                x2="200"
                y2="40"
                stroke="#2196f3"
                strokeWidth="2"
                transform={`rotate(${i * 3.6}, 200, 200)`}
              />
            ))}
          </g>

          {/* Rotating Arcs */}
          <g className="arc-group">
            {/* First Arc */}
            <path
              d="M200,50 
               A150,150 0 0,1 350,200
               L320,200 
               A120,120 0 0,0 200,80 
               Z"
              fill="url(#redGradient)"
              opacity={themeMode === "light" ? 0.8 : 0.2}
            />
          </g>

          <g className="arc-group">
            {/* Second Arc (Opposite Side) */}
            <path
              d="M200,350 
               A150,150 0 0,1 50,200
               L80,200 
               A120,120 0 0,0 200,320 
               Z"
              fill="url(#redGradient)"
              opacity={themeMode === "light" ? 0.8 : 0.2}
            />
          </g>
        </svg>
        <RotatingArcs />
      </div>
    </div>
  );
};

const RotatingArcs = () => {
  const theme = useTheme();
  const themeMode = useSelector(selectActiveTheme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      className="rotating-arcs-svg"
    >
      <g className="rotating-arcs">
        <defs>
          <linearGradient
            id="gradient1"
            gradientUnits="userSpaceOnUse"
            x1="200"
            y1="70"
            x2="330"
            y2="200"
          >
            <stop offset="60%" stop-color="rgba(0, 212, 255, 0.3)" />
            <stop offset="100%" stop-color="rgb(255, 255, 255, 0.6)" />
          </linearGradient>

          <linearGradient
            id="gradient2"
            gradientUnits="userSpaceOnUse"
            x1="200"
            y1="330"
            x2="70"
            y2="200"
          >
            <stop offset="60%" stop-color="rgba(0, 212, 255, 0.3)" />
            <stop offset="100%" stop-color="rgb(255, 255, 255,  0.6)" />
          </linearGradient>

          <linearGradient
            id="gradient3"
            gradientUnits="userSpaceOnUse"
            x1="200"
            y1="60"
            x2="340"
            y2="200"
          >
            <stop offset="80%" stop-color="rgba(0, 212, 255, 0.3)" />
            <stop offset="100%" stop-color="rgb(255, 255, 255,  0.6)" />
          </linearGradient>

          <linearGradient
            id="gradient4"
            gradientUnits="userSpaceOnUse"
            x1="200"
            y1="340"
            x2="60"
            y2="200"
          >
            <stop offset="80%" stop-color="rgba(0, 212, 255, 0.3)" />
            <stop offset="100%" stop-color="rgb(255, 255, 255,  0.6)" />
          </linearGradient>
        </defs>

        <path
          d="M200,70
       a130,130 0 0,1 130,130"
          fill="none"
          stroke="url(#gradient1)"
          stroke-width="8"
        />

        <path
          d="M200,330
       a130,130 0 0,1 -130,-130"
          fill="none"
          stroke="url(#gradient2)"
          stroke-width="8"
        />

        <path
          d="M200,60
       a140,140 0 0,1 140,140"
          fill="none"
          stroke="url(#gradient3)"
          stroke-width="1"
        />

        <path
          d="M200,340
       a140,140 0 0,1 -140,-140"
          fill="none"
          stroke="url(#gradient4)"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default RotatingCircle;
