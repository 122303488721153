import React from "react";
import RotatingCircle from "./RotatingCircle";
import "./ai-coming-soon.css";
import { useTheme } from "@emotion/react";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";
import { useTranslation } from "react-i18next";

const AiComingSoon = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { HeadingRegular, ParagraphRegular } = useFontSizes();
  return (
    <div
      className="coming-soon-section"
      style={{
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.text.primary,
        marginTop:"-2px"
      }}
    >
      <div className="container">
        <RotatingCircle />
        <h2 style={{ fontSize: HeadingRegular, fontWeight: "400" }}>
        {t("AiAnnouncement.title")}
        </h2>
        <p style={{ fontSize: ParagraphRegular, fontWeight: "400" }}>
        {t("AiAnnouncement.description")}
        </p>
      </div>
    </div>
  );
};

export default AiComingSoon;
