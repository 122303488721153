import "./what-we-offer-card.css";
import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useIntersectionObserver } from "../../../helpers/hooks/useIntersectionObserver";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";

function ServiceCard({
  icon,
  title,
  image,
  description,
  isSmallScreen,
  animationDelay,
  className,
  onClick, 
  style,
}) {
  const theme = useTheme();
  const { HeadingSmaller, ParagraphSmaller } = useFontSizes();
  const { ref: cardRef, inView: cardInView } = useIntersectionObserver();
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (cardInView) {
      setIsVisible(true);
    }
  }, [cardInView]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const isMiddle = className.includes("middle"); 
  return (
    <div
      ref={cardRef}
      className={className}
      onClick={onClick} 
      style={{
        minHeight: isSmallScreen ? "300px" : "350px",
      }}
    >
      <div
        className="card-outline"
        style={{
          "--box-shadow-color": theme.palette.hover.boxShadow,
          "--animation-delay": `${animationDelay}s`,
          backgroundColor: theme.palette.primary.mainTransparent,
          padding: isSmallScreen ? "8px" : "30px",
          position: "relative",
          overflow: "hidden",
          "--border-color": `${theme.palette.text.primaryBorder}`,
          perspective: "800px", 
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="card-background"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: isMiddle ? "0.5" : isHovered ? "0.3" : "0.15",
          }}
        />
        <div
          className="card-icon"
          style={{
            position: "absolute",
            top: "15%",
            left: "15%",
            color: isHovered ? theme.palette.text.primary : theme.palette.text.icon,
            transform: isHovered
              ? "translate(-50%, -50%) scale(1.4)"
              : "translate(-50%, -50%) scale(1.2)",
            transition: "transform 0.4s ease-in-out, color 0.4s ease-in-out",
            zIndex: 1,
          }}
        >
          {icon}
        </div>
      </div>
      <div className="card-text-container">
        <h3
          className="card-title"
          style={{ fontWeight: "500", transition:"font-size 0.4s ease-in-out" }}
        >
          {title}
        </h3>
        {isMiddle && (
          <p
            className="card-description"
            style={{
              fontSize: ParagraphSmaller,
              fontWeight: "300",
              color: theme.palette.text.primary,
            }}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
}

export default ServiceCard;
