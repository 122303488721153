import React from "react";
import { ReactComponent as LogoBlack } from "../../../media/logo/logo-full-black-cropped.svg";
import { ReactComponent as LogoWhite } from "../../../media/logo/logo-full-white-cropped.svg";
import { ReactComponent as MobileLogoBlack } from "../../../media/logo/logo-single-black.svg";
import { ReactComponent as MobileLogoWhite } from "../../../media/logo/logo-single-white.svg";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { selectActiveTheme } from "../../../store/reducers/userSlice";
import "../header.css";
import "../../header-mobile/headermobile.css";
import { selectScreenSize } from "../../../store/reducers/layoutSlice";
import { useNavigate } from "react-router-dom";

function Logo() {
	const themeMode = useSelector(selectActiveTheme);
	const screenSize = useSelector(selectScreenSize);
	const navigate = useNavigate(); 

	const handleLogoClick = () => {
		navigate("/"); 
	};

	if (isMobile || screenSize === "small") {
		return themeMode === "light" ? (
			<MobileLogoBlack className="logo-mobile" onClick={handleLogoClick} />
		) : (
			<MobileLogoWhite className="logo-mobile" onClick={handleLogoClick} />
		);
	}

	return themeMode === "light" ? (
		<LogoBlack className="logo-desktop" onClick={handleLogoClick} />
	) : (
		<LogoWhite className="logo-desktop" onClick={handleLogoClick} />
	);
}

export default Logo;
