import { useEffect, useState, useRef } from "react";

export function useIntersectionObserver(threshold = 0.3, rootMargin = "0px") {
  const elementRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    // Validate threshold
    const validThreshold =
      typeof threshold === "number" && threshold >= 0 && threshold <= 1
        ? threshold
        : 0.1;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Update `inView` based on whether the element is in view
          setInView(entry.isIntersecting);
        });
      },
      {
        threshold: validThreshold,
        rootMargin, // Add rootMargin for better control
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [threshold, rootMargin]);

  return { ref: elementRef, inView };
}
