import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: "#FFFFFF",
            mainGradientLighter: "#DCCC85",
            mainGradient: "#FBE998",
            mainGradient2: "#FFFCF3",
            mainGradientDarker: "#FBE998",
            squares:"rgba(255, 255, 255, 1)",
            squares2:"#FBE998",
            squaresHeader:"rgba(255, 255, 255, 1)",
            mainTransparent: "rgba(255, 255, 255, 0.98)",
            lighter: "rgb(245, 252, 251)",
            glass: "rgba(255, 255, 255, 0.3)",
            darker: "#EDEDED",
          },
          text: {
            primary: "#000000",
            icon: "rgba(0, 0, 0, 0.5)",
            primaryBorderButton: "rgba(0, 0, 0, 0.4)",
            primaryBorder: "rgba(0, 0, 0, 0.4)",
            secondary: "#333",
            third: "#666",
            blueButton: "#333",
            opposite: "#FFFFFF",
            hover:"",
          },
          hover: {
            main: "rgba(238, 231, 221, 0.35)",
            boxShadow: "rgba(0, 0, 0, 0.5)",
          },
          lightblue: {
            main: "#6ae7da",
            lighter: "#B3E8B9",
          },
          divider: grey[400],
        }
      : {
          primary: {
            main: "#15322C",
            mainGradientLighter: "#15322C",
            mainGradient: "#3B8A7F",
            mainGradient2: "#3B8A7F",
            mainGradientDarker: "#15322C",
            squares:"rgba(0, 0, 0, 0.2)",
            squares2:"rgba(0, 0, 0, 0.2)",
            squaresHeader:"#3B8A7F",
            mainTransparent: "rgba(21, 50, 44, 0.50)",
            lighter: "#386D60",
            glass: "rgba(21, 50, 44, 0.3)",
            darker: "#204942",
          },
          text: {
            primary: "#FFFFFF",
            icon: "rgba(255, 255, 255, 0.5)",
            primaryBorderButton:"rgba(255, 255, 255, 0.3)",
            primaryBorder: "rgba(255, 255, 255, 0.4)",
            secondary: grey[300],
            third: grey[400],
            blueButton: "#333",
            opposite: "#000000",
          },
          hover: {
            main: "rgba(238, 231, 221, 0.2)",
            boxShadow: "rgba(255, 255, 255, 0.5)",
          },
          lightblue: {
            main: "#6ae7da",
            lighter: "#3B8A7F",
          },
          divider: grey[400],
        }),
  },
});

export const themeCreation = (mode) =>
  createTheme({
    ...getDesignTokens(mode),
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: mode === "light" ? grey[900] : grey[50],
            color: mode === "light" ? grey[50] : grey[900],
          },
        },
      },
    },
  });
