import React from "react";
import { ReactComponent as LightIcon } from "../../../media/mode/light-mode.svg";
import { ReactComponent as DarkIcon } from "../../../media/mode/dark-mode.svg";
import { useTranslation } from "react-i18next";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";

function ThemeToggle({ currentTheme, handleModeSelect, theme }) {
  const { ParagraphSmallest } = useFontSizes();
  const { t } = useTranslation();

  return (
    <div
      className="mode-container"
      onClick={() =>
        handleModeSelect(currentTheme === "light" ? "dark" : "light")
      }
      style={{
        fontSize: ParagraphSmallest,
      }}
    >
      <div className="icon-container">
        <div
          className="background-icon"
          style={{
            "--border-color": `${theme.palette.text.primary}`,
          }}
        >
          {currentTheme === "dark" ? <LightIcon /> : <DarkIcon />}
        </div>
        <div className="dropdown-text-hovered">{currentTheme === "dark" ? t("lightMode") : t("darkMode")}</div>
      </div>
    </div>
  );
}

export default ThemeToggle;