import "./headermobile.css";
import React, { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import Logo from "../header/components/Logo";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderMobileModal from "./HeaderMobileModal";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";

function HeaderMobile() {
  const theme = useTheme();
  const screenSize = useSelector(selectScreenSize);
  const [isModalOpen, setModalOpen] = useState(false);
  const menuIconRef = useRef(null);

  const handleMenuClick = (event) => {
    event.stopPropagation(); 
    setModalOpen((prev) => !prev); 
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  return (
    <div
      className="header-container-mobile"
      style={{
        backgroundColor: theme.palette.primary.main,
        position: screenSize === "small" || isMobile ? "fixed" : "",
      }}
    >
      <Logo />

      <div className="menu-mobile">
        <MenuIcon
          ref={menuIconRef}
          style={{
            fontSize: "2.2rem",
            color: theme.palette.text.primary,
          }}
          onClick={handleMenuClick}
        />
      </div>

      {isModalOpen && (
        <HeaderMobileModal isOpen={isModalOpen} onClose={handleCloseModal} menuIconRef={menuIconRef} />
      )}
    </div>
  );
}

export default HeaderMobile;
